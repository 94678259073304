<template>
  <div
    class="search-histories"
    data-testid="search-candidates"
    @keydown.up="handleUp"
    @keydown.down.prevent="handleDown"
  >
    <div class="search-suggest-wrap js-criterias" v-if="criteria.length > 0">
      <p class="search-suggest__title">保存した検索条件</p>
      <ul class="search-suggest__list">
        <search-criteria
          :criterion="criterion"
          :index="index"
          :hasFocus="focus_criteria_index === index"
          v-for="(criterion, index) in criteria"
          :key="criterion.id"
        ></search-criteria>
      </ul>
    </div>
    <div
      class="search-suggest-wrap js-histories"
      v-if="histories.length > 0"
      data-testid="histories"
    >
      <p class="search-suggest__title">最近の検索</p>
      <button
        class="search-suggest__button--delete"
        type="button"
        @click="deleteAllHistories"
      >
        すべて消去
      </button>

      <ul class="search-suggest__list">
        <li
          class="history-list search-suggest__item"
          :class="{ focus: focus_history_index === index }"
          v-for="(history, index) in histories"
          :key="history.id"
        >
          <button
            class="search-suggest__item-main text-ellipsis"
            :class="`js-history-focus${index}`"
            type="button"
            @click="goToSearchResults(history, index)"
          >
            <i
              class="glyphs glyphs-search search-suggest__icon"
              aria-hidden="true"
            ></i>
            {{ history }}
          </button>
          <button
            class="search-suggest__item-sub"
            type="button"
            aria-label="set keyword"
            @click="setCurrentKeyword(history, index)"
          >
            <i class="glyphs glyphs-arrow_upper_left" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  SET_CURRENT_KEYWORD,
  FOCUS_SEARCH_INPUT
} from '../../store/mutation-types';
import { captureEvent } from '../../../common/utils';
import utils, { UNFOCUSED } from '../../utils';
import SearchCriteria from '../common/SearchCriteria';

export default {
  components: { SearchCriteria },

  data: function() {
    return {
      searchInputEl: null
    };
  },

  mounted: function() {
    this.searchInputEl = document.getElementById('sp_search_input');
  },

  watch: {
    focus_candidate_index: function(newIndex, _oldIndex) {
      // フォーカスが外れた場合はSearchInput.vueで処理
      if (newIndex !== UNFOCUSED) {
        let searchButtonEl = null;
        if (newIndex < this.criteria.length) {
          // 検索条件
          searchButtonEl = this.$el.querySelector(
            `.js-criteria-focus${this.focus_criteria_index}`
          );
        } else {
          // 最近の検索
          searchButtonEl = this.$el.querySelector(
            `.js-history-focus${this.focus_history_index}`
          );
        }
        // フォーカスが外れた場合はSearchInput.vueで処理

        if (searchButtonEl) searchButtonEl.focus();
      }
    }
  },

  computed: {
    ...mapGetters([
      'criteria',
      'histories',
      'focus_candidate_index',
      'focus_criteria_index',
      'focus_history_index'
    ])
  },

  methods: {
    ...mapActions([
      'decrementFocusCandidateIndex',
      'incrementFocusCandidateIndex',
      'initializeHistories'
    ]),

    deleteAllHistories() {
      if (!confirm('検索履歴を全て消去します。\nよろしいですか？')) {
        return;
      }
      this.initializeHistories();
    },

    goToSearchResults(keyword, index) {
      captureEvent({
        category: 'サイト内検索',
        action: 'SearchPartsClick',
        label: 'recently-search',
        value: index + 1
      });
      this.$store.commit(SET_CURRENT_KEYWORD, keyword);
      utils.goToSearchURL('/search/topics', { q: keyword });
    },

    setCurrentKeyword(keyword, index) {
      captureEvent({
        category: 'サイト内検索',
        action: 'SearchPartsClick',
        label: 'input-search',
        value: index + 1
      });
      // 続けて語句を入力しやすいように末尾にスペースを付与
      // ref: https://jira.m3internal.com/browse/ASKD-1078
      this.$store.dispatch('updateKeywordAndSuggestions', `${keyword} `);
      this.$store.commit(FOCUS_SEARCH_INPUT);
    },

    handleUp() {
      this.decrementFocusCandidateIndex();
    },

    handleDown() {
      this.incrementFocusCandidateIndex();
    }
  }
};
</script>
