<template>
  <div class="search-suggest__add-attribute" data-testid="search-attributes">
    <ul>
      <li
        v-for="(item, index) in gender_items"
        :key="index"
        :class="['search-suggest-tag']"
      >
        <a class="g-icon-add icon-size-s" @click="handleClick(item.label)">{{
          item.label
        }}</a>
      </li>
      <li
        v-for="(item, index) in age_items"
        :key="index"
        :class="['search-suggest-tag', 'mobile-only']"
      >
        <a class="g-icon-add icon-size-s" @click="handleClick(item.label)">{{
          item.label
        }}</a>
      </li>
    </ul>
    <ul class="pc-only">
      <li
        v-for="(item, index) in age_items"
        :key="index"
        :class="['search-suggest-tag']"
      >
        <a class="g-icon-add icon-size-s" @click="handleClick(item.label)">{{
          item.label
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { captureEvent } from '../../../common/utils';
import utils from '../../utils';
export default {
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gender_items: [{ label: '女性' }, { label: '男性' }],
      age_items: [
        { label: '乳幼児' },
        { label: '10歳未満' },
        { label: '10代' },
        { label: '20代' },
        { label: '30代' },
        { label: '40代' },
        { label: '50代' },
        { label: '60代' },
        { label: '70代以上' },
      ],
    };
  },
  methods: {
    ...mapActions(['setCurrentKeyword']),

    handleClick(label) {
      captureEvent({
        category: 'サイト内検索',
        action: 'SearchPartsClick',
        label: 'add-attribute',
        value: 1,
      });

      this.setCurrentKeyword(`${this.keyword.trim()} ${label}`);

      utils.goToSearchURL('/search/topics', {
        q: `${this.keyword.trim()} ${label}`,
      });
    },
  },
};
</script>
