<template>
  <div
    class="search-suggest"
    v-bind:class="{ 'search-suggest--second': isSecond }"
    data-testid="search-suggestions"
  >
    <search-attributes v-if="!inTop" :keyword="keyword"></search-attributes>
    <search-candidates v-if="search_candidate_active" :keyword="keyword" />
    <keyword-suggestions v-if="search_suggestion_active" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchCandidates from './SearchCandidates.vue';
import KeywordSuggestions from './KeywordSuggestions.vue';
import SearchAttributes from '../common/SearchAttributes';

export default {
  components: { SearchCandidates, KeywordSuggestions, SearchAttributes },
  props: {
    isSecond: {
      type: Boolean,
      default: false,
    },
    inTop: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['search_candidate_active', 'search_suggestion_active'])
  },
};
</script>
